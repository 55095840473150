// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-boys-js": () => import("./../../../src/pages/boys.js" /* webpackChunkName: "component---src-pages-boys-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-girls-js": () => import("./../../../src/pages/girls.js" /* webpackChunkName: "component---src-pages-girls-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-import-js": () => import("./../../../src/pages/productsImport.js" /* webpackChunkName: "component---src-pages-products-import-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/return-policy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */),
  "component---src-pages-shipping-policy-js": () => import("./../../../src/pages/shipping-policy.js" /* webpackChunkName: "component---src-pages-shipping-policy-js" */),
  "component---src-pages-siblings-js": () => import("./../../../src/pages/siblings.js" /* webpackChunkName: "component---src-pages-siblings-js" */),
  "component---src-pages-size-chart-js": () => import("./../../../src/pages/size-chart.js" /* webpackChunkName: "component---src-pages-size-chart-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */)
}

